import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// React icons
// import { FaTwitter, FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
// import CardFooter from "components/Card/CardFooter.jsx";

import teamStyle from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.jsx";

import detail1 from "assets/img/detail1.jpg";
import detail2 from "assets/img/detail2.jpg";
import detail3 from "assets/img/detail3.jpg";

class FactsSection extends React.Component {
  render() {
    const { classes } = this.props;
    const imageClasses = classNames(
      classes.imgRaised,
      classes.imgRounded,
      classes.imgFluid
    );
    return (
      <div className={classes.section}>
        <h2 className={classes.title}>And what if you...</h2>
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <Card plain>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={detail1} alt="..." className={imageClasses} />
              </GridItem>
                <h4 className={classes.cardTitle}>
                  Want a full mix
                  <br />
                  <small className={classes.smallTitle}>from scratch ?</small>
                </h4>
                <CardBody>
                  <p className={classes.description}>
                    My main activity.<br/>I can start from raw recordings or pre-processed tracks including your selected fx.
                  </p>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card plain>
                <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                  <img src={detail2} alt="..." className={imageClasses} />
                </GridItem>
                <h4 className={classes.cardTitle}>
                  Already have a stereo mix
                  <br />
                  <small className={classes.smallTitle}>you're happy with ?</small>
                </h4>
                <CardBody>
                  <p className={classes.description}>
                    No problem.<br/>I'll guide you to prepare your processed tracks and fx setups to recreate your mix in a 3D space.
                  </p>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card plain>
                <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                  <img src={detail3} alt="..." className={imageClasses} />
                </GridItem>
                <h4 className={classes.cardTitle}>
                  Made your own 3D mix
                  <br />
                  <small className={classes.smallTitle}>using headphones ?</small>
                </h4>
                <CardBody>
                  <p className={classes.description}>
                    Fantastic.<br/>Using a true 7.1.4 speaker setup, I will help you making sure that your mix translates as intended.
                  </p>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(teamStyle)(FactsSection);
